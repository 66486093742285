import React, { useState } from 'react';
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Layout from "../components/layout"

export default function PrivacyList({ pageContext: { node },location }) {
    const [headerColor,setHeaderColor] = useState("bg-white text-black")

    return (
        <Layout bgColor={'bg-white'} footer={true} headerColor={headerColor}>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'sotto',
              }}
            />
            <div className="container mx-auto pt-[200px] pb-[100px] flex items-start justify-between sm:pt-24 sm:pb-20">
                
                <article className="w-[944px] ml-auto privacy xl:w-full">
                    <h1 className="text-[40px] leading-snug">{node.title}</h1>
                    <div dangerouslySetInnerHTML={{__html: node.content}} />
                </article>
            </div>
        </Layout>
    )
}

